import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
  {
    path: '*',
    redirect: '/home'
  },{
    name: 'home',
    component: () => import('./view/site/index'),
    meta: {
      title: '移动官网'
    }
  },{
    name: 'school-detail',
    component: () => import('./view/site/school'),
    meta: {
      title: '学校介绍'
    }
  },
  // {
  //   name: 'banking',
  //   component: () => import('./view/banking/index'),
  //   meta: {
  //     title: '排行榜'
  //   }
  // },{
  //   name: 'signup',
  //   component: () => import('./view/signup/index'),
  //   meta: {
  //     title: '我要报名'
  //   }
  // },
  // {
  //   name: 'setting',
  //   component: () => import('./view/setting'),
  //   meta: {
  //     title: '设置'
  //   }
  // },
  // {
  //   name: 'player',
  //   component: () => import('./view/player/index'),
  //   meta: {
  //     title: '选手详情'
  //   }
  // }
];

// add route path
routes.forEach(route => {
  route.path = route.path || '/' + (route.name || '');
});

const router = new Router({  mode: 'history', routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export {
  router
};
